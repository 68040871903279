<template>
<div
    class="channel__page"
  >
<div class="row channel__column">
<vue-plyr ref="plyr">
        <video :id="xid" data-plyr-config="{'autoplay': true}" type="application/vnd.apple.mpegurl">
        </video>
    </vue-plyr>
</div>
<chat :channel="channelData.xid" />
<div class="channelData">
    <router-link :to="`/${channelData.slug}`">
      <b-avatar
        size="50"
        :src="`https://assets.brimecdn.com/cdn-cgi/image/width=80,quality=100/users/${channelData.owner.xid}/avatar`"
        variant="light-primary"
        class="badge-minimal hide-on-mobile"
      />
    </router-link>
    <span style="margin-left: 10px;">
      <span
        style="
                vertical-align: top;
                color: white;
                font-size: 16px;
              "
        class="channelName"
        id="channelName"
      >
        <img
          v-if="channelData.verified"
          src="https://beta.brimelive.com/brime_verified.png"
          style="padding-left: 5px"
        >
        {{ channelData.slug}} &bull; <span style="color: #999999; font-size: 14px;"> {{vod.title}}</span></span>
      <span
        class="streamCategory"
      >
       <!--- <router-link :to="`/category/${channelData.channel.slug}`">{{channelData.channel.slug}}</router-link>
        &bull;--->
        <span class="streamTitle">
        Broadcast Date:<span style="color: #7367f0"> {{date}}</span>
        </span>
        <b-button
        class="ml-1"
        v-b-modal.share-vod
        variant="outline-primary"
        size="sm"
      ><feather-icon icon="ShareIcon" class="" />
        Share
      </b-button>
      </span>
    </span>
    <br>
    </div>
    <!-- modal vertical center -->
    <b-modal
      id="share-vod"
      ref="share-vod"
      :hide-footer="true"
      centered
      title="Share"
      ok-only
      ok-title="Accept"
    >
    <b-form-input
          id="basicInput"
          :value="vodURL"
        />
        <b-form-checkbox
        class="mt-1"
      v-model="shareWithTimestamp"
    >
      Share with timestamp
    </b-form-checkbox>
    <b-button @click="copyURL" size="sm" class="float-right" variant="primary">
      Copy Link
    </b-button>
    </b-modal>
</div>
</template>

<script>
import VuePlyr from 'vue-plyr';
import Hls from 'hls.js';
import Chat from '../../../components/chat/chat.vue'
import {
  BAvatar, BButton, VBTooltip, BModal, BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormCheckbox,
} from 'bootstrap-vue'

export default {
props: ['xid'],
components: {
  BFormCheckbox,
  BFormInput,
    BFormGroup,
    BRow,
    BCol,
      BAvatar,
  BButton, 
    VuePlyr,
    Chat,
    BModal
},
data() {
return {
    shareWithTimestamp: null,
    date: '',
    timestamp: null,
    vod: {},
    vodURL: `https://brime.tv/videos/${this.xid}`,
    currentTime: 0,
    channelData: {
        verified: false,
        xid: false,
        channel: { slug: "" },
        owner: {},
        stream: { category_name: "" },
      },
    playerOptions: {
                controls: ['play-large', 'play', 'progress', 'current-time', 'duration', 'mute', 'volume', 'settings', 'fullscreen'],
                settings: ['quality', 'speed', 'loop'],
            }
}
},
async created(){
  this.vod = await this.getVod(this.xid)
  this.channelData = await this.channelLookupByXID(this.vod.channel_xid)
},
async mounted() {
    document.body.classList.add('channel__page')
    const vod = await this.getVod(this.xid)
  this.vod = vod
  const channelData = await this.channelLookupByXID(this.vod.channel_xid)
  this.channelData = channelData
  var utcTimestamp = this.vod.start_time
    var d = new Date(0);
    var date = new Date(vod.start_time); 
    this.date = date.toLocaleString( {hour12:true } ).replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
    this.timestamp = this.$route.query.t
    this.$refs.plyr.player.on('loadeddata', () => {
      this.setTimestamp(this.timestamp)
    })
},
methods: {
  setTimestamp(value){
    console.log(value)
    this.player.currentTime = parseInt(value)
    this.player.play()
  },
  copyURL(){
    navigator.clipboard.writeText(this.vodURL)
    this.$refs['share-vod'].hide()
    let variant = 'primary'
    this.$bvToast.toast('Link Copied!', {
        title: `Share`,
        variant,
        solid: true,
      })
  }
},
watch:{ 
  shareWithTimestamp(){
    if(this.shareWithTimestamp){
    this.vodURL = this.vodURL + '?t=' + this.currentTime
    } else {
      this.vodURL = `https://brime.tv/videos/${this.xid}`
    }
  },
  channelData(){
    this.player = this.$refs.plyr.player;
            const hls = new Hls();
            hls.loadSource(`https://vods.brimecdn.com/${this.vod.channel_xid}/${this.xid}/index-dvr.m3u8`);
            hls.attachMedia(this.player.media);
            this.player.pause()
            // this.$refs.plyr.player.play()
            
  this.player.on('timeupdate', (event) => {
  this.currentTime = Math.floor(this.player.currentTime)
});         
  },
},
destroyed() {
    document.body.classList.remove('channel__page')
    document.title = 'Brime'
  },
}
</script>
<style>
.vod__title {
  margin-top: 0.5rem;
  font-weight: bolder;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.vod__image {
  position: relative;
  opacity: 1;
  transition: opacity 0.2s;
}
.vod__duration {
  z-index: 1;
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 0px 6px;
  background: #7367f0;
  color: white;
  border-radius: 3px;
  font-size: 0.9rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.vod__category,
.vod__date {
  font-size: 1em;
  color: #fff;
}
.tabSection {
  margin-top: 8vh;
  max-width: 78%;
}
.vodSection {
  margin-top: 5vh;
}
.btn-outline-primary {
border-top-right-radius: 5px !important;
border-bottom-right-radius: 5px !important;
}
.subscribe {
border-radius: 5px !important;
}
.btn-icon {
border-top-left-radius: 5px !important;
border-bottom-left-radius: 5px !important;
}
.newFollowButton{
border-top-left-radius: 5px !important;
border-bottom-left-radius: 5px !important;
border-top-right-radius: 0px !important;
border-bottom-right-radius: 0px !important;
}
.dropdown-toggle{
margin-left: 10px !important;
padding-left: 2px !important;
padding-right: 2px !important;
background-color: unset !important;
border-color: #7367f0 !important;
border: 5px !important;
}
.actionButtons {
  padding-top: 0.25rem;
  right: calc(21%) !important;
  }
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body.channel__page .app-content {
  padding-top: var(--headerHeight) !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
 .channelData {
    font-size: 14px !important;
    margin-top: 15px !important;
    margin-left: 1rem !important;
  }
  .streamCategory {
    display: block;
  }
    .viewcount {
    font-size: 12px !important;
  }
.row{
  margin-left: 0px !important;
}
.channel__column > .plyr {
  width: 100%;
}
.plyr__poster{
  background-size: contain;
}
.plyr__controls{
  max-height: 80vh !important;
}
.dark-layout .nav-tabs .nav-item .nav-link.active {
  background-color: unset !important;
}
.plyr {
  --plyr-color-main: linear-gradient(90deg, #833ab4, #fd1d1d) !important;
  font-family: 'Roboto', sans-serif;
}
.loadingClip {
  width: 100%;
}
.clipVideo {
  width: 100%;
}
.clipButton {
  margin-top: 5px;
  margin-right: 30px;
}
.subButton {
  margin-top: 5px;
  margin-right: 0px;
}
.followers {
  padding-left: 10px;
  font-size: 14px;
}
.streamTitle {
  padding-top: 5px;
  padding-left: 10px;
  font-size: 14px;
}
.streamCategory {
  margin-top: -25px;
  margin-left: 55px;
  font-size: 14px;
}


/* Small screens */
@media all and (max-width: 767px) {
  .hide-on-mobile {
    display: none;
  }
.channelData {
    font-size: 14px !important;
    margin-top: 7px !important;
    margin-left: 0.5rem !important;
    padding-bottom: 5px;
  }
  #channelName {
    font-size: 10px !important;
  }
  .followCount{
    display: none;
  }
  .actionButtons {
    margin-top: -75px !important;
    right: -15px !important;
    position: fixed;
  }
  #channel {
    font-size: 14px !important;
    margin-top: 15px !important;
    margin-left: 2px !important;
  }
  .viewcount {
    font-size: 12px !important;
  }
  .streamCategory {
    margin-top: 7px !important;
    margin-left: 0 !important;
  }
  #followersText {
  display: none;
  }
}
/* Medium and large screens */
@media all and (min-width: 768px) {
  #stream-video {
    /* override other styles to make responsive */
    width: 100% !important;
    min-height: 84vh !important;
  }
}
</style>
<style scoped>
.card:hover {
  transform: translateY(-3px);
  box-shadow: 0px 0px 20px rgba(115, 103, 240, 1);
}

.card img {
  transition: all 0.2s;
}
.card {
  box-shadow: none;
  padding: 0.5rem;
}
.vod__category {
  color: #7367f0 !important;
}
.card-body {
  padding: unset !important;
}
.card {
  background-color: unset !important;
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-4px);
}

.card:hover .vod__image {
  opacity: 1;
}

.vod__title {
  margin-top: 0.5rem;
  font-weight: bolder;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vod__image {
  position: relative;
  opacity: 1;
  transition: opacity 0.2s;
}

.vod__duration {
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 0px 6px;
  background: #7367f0;
  color: white;
  border-radius: 3px;
  font-size: 0.9rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.vodSection {
  margin-top: 5vh;
}
.vod__date {
  vertical-align: middle;
  display:inline-block;
  font-size: 1em;
  color: #fff;
  line-height: 1em;
}
.vod__clipper {
  display: inline;
  font-size: 0.9em !important;
  color: #B4B7BD;
  line-height: 1em;
}
#stream-video {
  /* override other styles to make responsive */
  width: 100% !important;
}
.disabled {
  margin-left: 0px !important;
}
.actionButtons {
  margin-top: -50px;
  margin-right: 20px;
}
.creatorMenu {
  margin-top: 10px;
}
#doge-canvas {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 9001;
}
.disable-scrollbars::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
html {
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
#channel {
  margin-top: 15px;
  top: 0;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  vertical-align: middle;
}
#channel img {
  border-radius: 50%;
  vertical-align: middle;
}
.row {
  margin-top: 0;
}
.channel__column {
  flex-wrap: nowrap;
}

#player {
  flex-shrink: 0;
}
.content-body {
  height: 100% !important;
}

.channel__column {
    width: calc(100% - max(20vw, 250px));
}

@media screen and (max-width: 768px) {
  .channel__column {
    width: 100%;
    flex-shrink: 0;
  }

  .channel__page {
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--headerHeight));
  }

  .content {
    padding: 0px;
  }

  #player {
    max-height: 80%;
  }
}
textarea {
  width: 200px;
  resize: none;
  overflow: hidden;
  font-size: 18px;
  height: 1.1em;
  padding: 2px;
}
</style>
